import * as jquery from "jquery";

export const onClientEntry = () => {
  jquery(document).ready(() => {
    console.log("Document ready");
    setTimeout(() => {
      window.startGo = true;
      console.log("Go!", window.startGo);
    })
  })

  console.log('Eventos cargados')
}
